import { create } from 'zustand';


type State = {
  selectedDate: string;
}

type Action = {
  updateSelectedDate: (selectedDate: string) => void;
}

export const useSelectedDateStore = create<State & Action>((set) => ({
  selectedDate: '',
  updateSelectedDate: (selectedDate: string) => set({ selectedDate }),
}));