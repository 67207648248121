
import React, { FC, createContext, useContext, useEffect, useState } from "react";

import { API_SOCKET_URL, API_DOWNLOAD_FILE_URL } from '../utils/config';
import {
    ICarcass,
    IPopVisualizationDownloadEntry,
    IPopVisualizationDownloadContext
} from "../types";
import { useInformation } from "./information";
import { useCarcassesPops } from "./carcassesPops";
import { PopRelatedPhoto } from "../utils/pops";
import { useBranchStore } from "../stores/branch";
import { useSelectedDateStore } from "../stores/selected_date";


const PopVisualizationDownloadContext = createContext<IPopVisualizationDownloadContext>(
    {} as IPopVisualizationDownloadContext);


export const PopVisualizationDownloadProvider: FC<any> = ({ children }) => {
    const [data, setData] = useState<IPopVisualizationDownloadEntry[]>([]);
    const [selectedPops, setSelectedPops] = useState<string[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const [urlDownload, setUrlDownload] = useState<string>('');
    const [fileNameDownload, setFileNameDownload] = useState<string>('');
    const [currentIndexDownload, setCurrentIndexDownload] = useState<number>(0);
    const [totalDownload, setTotalDownload] = useState<number>(0);
    const [downloading, setDownloading] = useState<boolean>(false);

    const { showInformation } = useInformation();
    const { pops } = useCarcassesPops();    // assume we will have fetched pops

    const storeBranchId = useBranchStore((state) => state.branchId);
    const storeSelectedDate = useSelectedDateStore((state) => state.selectedDate);

    const clear = () => {
        setData([]);
        setSelectedPops([]);
        setIsModalOpen(false);
    };

    useEffect(() => {
        console.log("Branch_id: ", storeBranchId);
        console.log("SelectedDate: ", storeSelectedDate);
    }, [storeBranchId, storeSelectedDate]);

    const convertCarcassToEntry = (carcass: ICarcass, side: 'A' | 'B', photoType: PopRelatedPhoto) => {
        let photoUri = carcass.photoUri;
        switch (photoType) {
            case PopRelatedPhoto.INTERNAL: photoUri = (
                side === 'A' ? carcass.internalUri : carcass.internalUriB);
                break;
            case PopRelatedPhoto.OCR: photoUri = (
                side === 'A' ? carcass.ocrUri : carcass.ocrUriB);
                break;
            case PopRelatedPhoto.OCR_HASH_1: photoUri = (
                side === 'A' ? carcass.ocrUriHash1 : carcass.ocrUriHash1B);
                break;
            default: photoUri = (
                side === 'A' ? carcass.photoUri : carcass.photoUriB);
                break;
        };
        let pops = side === 'A' ? carcass.popsA : carcass.popsB;
        if (pops)
            pops = pops.filter((pop) => pop.relatedPhoto === photoType);

        return {
            id: carcass.id,
            photoUri: (photoUri as string),
            pops,
        } as IPopVisualizationDownloadEntry;
    };

    const toggleEntry = (value: IPopVisualizationDownloadEntry) => {
        let newData: IPopVisualizationDownloadEntry[] = data.filter(
            (entry: IPopVisualizationDownloadEntry) => (
                entry.id !== value.id || entry.photoUri !== value.photoUri)
        );

        if (newData.length === data.length)
            newData.push(value);

        setData(newData);
    };

    const startDownload = async (_fileName: string, refDownload: React.RefObject<HTMLAnchorElement>) => {
        setFileNameDownload(_fileName);
        setUrlDownload(`${API_DOWNLOAD_FILE_URL}/download_page/${_fileName}`);       
        try {
            setTimeout(() => {
                refDownload.current?.click();
                setTimeout(() => {
                    setUrlDownload('');
                    setFileNameDownload('');
                    setCurrentIndexDownload(0);
                    setTotalDownload(0);
                    setDownloading(false);
                }, 1000);
            }, 1000);
        } catch (err: any) {
            setDownloading(false);
        }
    };

    // const startDownloadAll = async (_fileName: string, refDownload: React.RefObject<HTMLAnchorElement>) => {
    //     setFileNameDownload(_fileName);
    //     setUrlDownload(`${API_DOWNLOAD_FILE_URL}/download_page/${_fileName}`);       
    //     try {
    //         setTimeout(() => {
    //             refDownload.current?.click();
    //             setTimeout(() => {
    //                 setUrlDownload('');
    //                 setFileNameDownload('');
    //                 setCurrentIndexDownload(0);
    //                 setTotalDownload(0);
    //                 setDownloading(false);
    //             }, 1000);
    //         }, 1000);
    //     } catch (err: any) {
    //         setDownloading(false);
    //     }
    // };

    const downloadAllCarcassesOfDay = async (
        refDownload: React.RefObject<HTMLAnchorElement>,
        day: string,
        branchId: string
    ) => {
        setDownloading(true);
        try {
            // Estabelecer conexão WebSocket
            const socket = new WebSocket(`${API_SOCKET_URL}socket/download_page_bulk`);
            console.log("Socket: ", socket);
            // Evento disparado quando a conexão for aberta
            socket.onopen = () => {
                // Enviar os parâmetros day e branch_id
                const message = JSON.stringify({ day: storeSelectedDate, branch_id: storeBranchId });
                console.log("Mensagem enviada:", message);
                socket.send(message);
            };
    
            // Evento disparado ao receber mensagens do servidor
            socket.onmessage = (event) => {
                const eventData = JSON.parse(event.data);
    
                if (eventData.status === "IN_PROGRESS") {
                    setCurrentIndexDownload(eventData.current || 0);
                    setTotalDownload(eventData.total || 0);
                } else if (eventData.status === "COMPLETED" && eventData.path) {
                    const fileUrl = `${API_DOWNLOAD_FILE_URL}/download_page_bulk/${eventData.path}`;
                    console.log("API_DOWNLOAD_FILE_URL", API_DOWNLOAD_FILE_URL);
                    console.log("fileUrl", fileUrl);
                    setUrlDownload(fileUrl);
                    setFileNameDownload(`cvat_images_annotations.zip`);
                    refDownload.current?.click();
                    socket.close();
                    showInformation("Download concluído com sucesso!", "Sucesso");
                } else if (eventData.status === "ERROR") {
                    showInformation(
                        eventData.message || "Erro ao processar o download.",
                        "Erro"
                    );
                    socket.close();
                    setDownloading(false);
                }
            };
    
            // Evento disparado em caso de erro na conexão
            socket.onerror = (error) => {
                console.error("Erro no WebSocket:", error);
                showInformation("Erro na conexão com o servidor.", "Erro");
                setDownloading(false);
            };
    
            // Evento disparado quando a conexão é fechada
            socket.onclose = () => {
                setDownloading(false);
            };
        } catch (error) {
            console.error("Erro ao fazer o download:", error);
            showInformation("Erro ao baixar as carcaças do dia. Tente novamente.", "Erro");
            setDownloading(false);
        }
    };
    
    

    const downloadImages = async (refDownload: React.RefObject<HTMLAnchorElement>) => {
        if (!data || data.length == 0) return;

        setDownloading(true);
        try {
            const socket = new WebSocket(`${API_SOCKET_URL}socket/download_page`)
            socket.onmessage = (ev: MessageEvent<any>) => {
                let eventData = JSON.parse(ev.data);
                if (eventData) {
                    if (eventData.current && eventData.total && eventData.status && eventData.status == 'IN_PROGRESS') {
                        setCurrentIndexDownload(parseInt(eventData.current))
                        setTotalDownload(parseInt(eventData.total))
                    } else if (eventData.path && eventData.status && eventData.status == 'COMPLETED') {
                        setCurrentIndexDownload(0);
                        setTotalDownload(0);

                        startDownload(eventData.path, refDownload);
                        socket.close();
                        clear();
                        showInformation("Exportação concluída com sucesso.", 'Sucesso');
                    } else if (eventData.message && eventData.status == 'ERROR') {
                        if (eventData.message == "NOT_FOUND")
                            showInformation("Não foi encontrada nenhuma imagem", 'Aviso');
                        else if (eventData.message == "MISSING_ARGS")
                            showInformation("Erro de conexão com o servidor. Entre em contato com nossa equipe de desenvolvimento", 'Aviso');
                        socket.close();
                        setDownloading(false);
                    }
                }
            };
            socket.onopen = (ev: Event) => {
                const message: any = {
                    data: data.reduce((res: any, curr: IPopVisualizationDownloadEntry) => {
                        let newRes = [...res];
                        if (!curr.pops || curr.pops.length === 0)
                            newRes.push({
                                id: curr.id,
                                photoUri: curr.photoUri,
                            });
                        else {
                            // filter selectedPops
                            const allowedPops: string[] = pops?.filter(
                                    pop => selectedPops.includes(pop.name)
                                ).map(pop => pop.name) || [];
                            if (
                                allowedPops.length > 0 &&
                                !curr.pops!.map((pop) => String(pop.name)).some(
                                    (_type) => allowedPops.includes(_type)
                                )
                            ) {
                                // append photo without any POP
                                newRes.push({
                                    id: curr.id,
                                    photoUri: curr.photoUri,
                                });
                            } else
                                for (const pop of curr.pops!) {
                                    if (
                                        allowedPops.includes(
                                            String(pop.name!)
                                        )
                                    )
                                        newRes.push({
                                            id: curr.id,
                                            photoUri: curr.photoUri,
                                            pop: pop.classification,
                                            popType: pop.popType,
                                            boundingBoxes: pop.boundingBoxes
                                        });
                                }
                        }
                        return newRes;
                    }, [] as any),
                    groupBy: 'pop',
                }
                socket.send(JSON.stringify(message));
            }
        } catch (err) {
            showInformation((err as any).message, 'Aviso');
            setDownloading(false);
        }
    };

    return (
        <PopVisualizationDownloadContext.Provider
            value={{
                data,
                setData,
                selectedPops,
                setSelectedPops,
                isModalOpen,
                setIsModalOpen,
                downloadImages,
                downloadAllCarcassesOfDay,
                urlDownload,
                setUrlDownload,
                fileNameDownload,
                setFileNameDownload,
                currentIndexDownload,
                setCurrentIndexDownload,
                totalDownload,
                setTotalDownload,
                downloading,
                setDownloading,
                toggleEntry,
                convertCarcassToEntry,
                clear,
            }}
        >
            {children}
        </PopVisualizationDownloadContext.Provider>
    );
};

export function usePopVisualizationDownload() {
    const context = useContext(PopVisualizationDownloadContext);

    if (!context) {
        throw new Error(
            "usePopVisualizationDownload must be used within an PopVisualizationDownloadProvider",
        );
    }

    return context;
}
