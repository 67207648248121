import { create } from 'zustand';

type State = {
  branchId: string;
}

type Action = {
  updateBranchId: (branchId: string) => void;
}

export const useBranchStore = create<State & Action>((set) => ({
  branchId: '',
  updateBranchId: (branchId: string) => set({ branchId }),
}));